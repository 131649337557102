var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-component", {
    ref: "table",
    attrs: {
      itemsRepo: _vm.profileContractRepo,
      sidebarData: _vm.planData,
      isDataFromModel: isNaN(+_vm.$route.params.itemData),
      fields: _vm.fields,
      loadParams: { ProfileId: +_vm.$route.params.itemData },
      isAddEditSidbar: false,
      getByIdOnEditFilter: function () {
        return false
      },
      addEditLink: {
        name: "practices-details-view-profiles-more",
        params: { planId: _vm.planData.id ? _vm.planData.id : "new" },
      },
    },
    on: {
      "update:sidebarData": function ($event) {
        _vm.planData = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.planData = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "cell(dateStartEnd)",
        fn: function (data) {
          return [
            _vm._v(
              "\n      " +
                _vm._s(
                  data.item.daetStart
                    ? new Date(data.item.daetStart).toLocaleDateString().trim()
                    : "N/A"
                ) +
                "\n      --\n      " +
                _vm._s(
                  data.item.dateEnd
                    ? new Date(data.item.dateEnd).toLocaleDateString()
                    : "N/A"
                ) +
                "\n    "
            ),
          ]
        },
      },
      {
        key: "cell(insurancePlan)",
        fn: function (data) {
          return _vm._l(
            data.item.profileContractInsurancePlans,
            function (plan) {
              return _c("div", { key: plan.id }, [
                _vm._v(
                  "\n        " + _vm._s(plan.insurancePlan.name) + "\n      "
                ),
              ])
            }
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }