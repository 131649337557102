var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c("form-component", {
            attrs: {
              copyData: _vm.profileCopy,
              showDelete: _vm.profile.id,
              showHeader: true,
              showStatus: _vm.profile.id,
              validate: true,
            },
            on: {
              "update:copyData": function ($event) {
                _vm.profileCopy = $event
              },
              "update:copy-data": function ($event) {
                _vm.profileCopy = $event
              },
              save: _vm.save,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.profile.id ? _vm.profile.name : "Add New Profile"
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "status-right",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex float-right text-right" },
                      [
                        _vm._v("\n          Primary\n          "),
                        _c("b-form-checkbox", {
                          staticClass: "ml-1 custom-control-light",
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value: _vm.profileCopy.isPrimary,
                            callback: function ($$v) {
                              _vm.$set(_vm.profileCopy, "isPrimary", $$v)
                            },
                            expression: "profileCopy.isPrimary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Profile Name",
                                              "label-for": "profileName",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Profile Name",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "profileName",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.profileCopy
                                                              .name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.profileCopy,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "profileCopy.name",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _vm.mode == "edit"
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Profile Id",
                                                  "label-for": "profileId",
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Profile Id",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "profileId",
                                                                placeholder: "",
                                                                disabled: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .profileCopy
                                                                    .id,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.profileCopy,
                                                                      "id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "profileCopy.id",
                                                              },
                                                            }),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1063409328
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Short Code",
                                              "label-for": "profileShortCode",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Short Code",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "profileShortCode",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.profileCopy
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.profileCopy,
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "profileCopy.code",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Provider Name",
                                              "label-for": "providerName",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Provider Name",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          id: "providerName",
                                                          options:
                                                            _vm.providerOptions,
                                                          "text-field": "name",
                                                          "value-field": "id",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.profileCopy
                                                              .providerId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.profileCopy,
                                                              "providerId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "profileCopy.providerId",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Corporation Name",
                                              "label-for": "corpName",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Corporation Name",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          id: "corpName",
                                                          options:
                                                            _vm.corporationOptions,
                                                          "text-field": "name",
                                                          "value-field": "id",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.profileCopy
                                                              .corporationId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.profileCopy,
                                                              "corporationId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "profileCopy.corporationId",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Locations",
                                              "label-for": "locations",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Locations",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("infinit-select", {
                                                        attrs: {
                                                          repo: _vm.locationsRepo,
                                                          "include-ids":
                                                            _vm.selectedLocations,
                                                          filter: {
                                                            PracticeId:
                                                              +_vm.$route.params
                                                                .id,
                                                          },
                                                          placeholder:
                                                            "Select Locations",
                                                          multiple: "",
                                                          label: "name",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedLocations,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedLocations =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedLocations",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Insurances",
                                              "label-for": "insurances",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Insurances",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("infinit-select", {
                                                        attrs: {
                                                          repo: _vm.insuranceRepo,
                                                          "include-ids": [
                                                            _vm.profileCopy
                                                              .insuranceId,
                                                          ],
                                                          filter: {
                                                            PracticeId:
                                                              +_vm.$route.params
                                                                .id,
                                                            OnlyReviewed: true,
                                                          },
                                                          placeholder:
                                                            "Select Insurances",
                                                          label: "name",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.profileCopy
                                                              .insuranceId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.profileCopy,
                                                              "insuranceId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "profileCopy.insuranceId",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.profile,
              callback: function ($$v) {
                _vm.profile = $$v
              },
              expression: "profile",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }